import React from "react"
import Img from "gatsby-image"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo/seo"
import Banner from "../../components/banner/banner"
import Charges from "../../components/about/charges/charges"
import Links from "../../components/about/links/links"

export default function ChargesAndFees({ data }) {
  return (
    <Layout>
      <SEO title="Charges and Fees" />
      <Banner
        image={
          <Img
            fluid={data.image.childImageSharp.fluid}
            style={{ height: "100%" }}
          />
        }
        title="About Us"
      />
      <div className="width75">
        <h1 className="title">Charges and Fees</h1>
        <Links type="charges" />
        <div className="ribbon-top">
          <h2 className="sub-title">
            We have the unbundled (pay as you go) legal services{" "}
          </h2>
          <p>
            In such arrangement the client allocates our solicitor specific
            tasks, where the client wishes to ensure that legal costs are kept
            to a minimum. Our solicitor and the client work closely at the
            outset in order to decide who is responsible for which task of the
            matter, and thus our solicitor charges for those tasks only.
          </p>
        </div>
        <div className="ribbon-top">
          <h2 className="sub-title">Fixed Fees</h2>
          <Charges />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    image: file(relativePath: { eq: "lawers.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
