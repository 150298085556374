import React from "react"
import styles from "./charges.module.css"

const data = [
  {
    description: "First Consultation and case assessment (up to 30 Minutes)",
    fee: "Free",
  },
  {
    description:
      "Divorce/dissolution of civil partnership (petitioner – uncontested)",
    fee: "£1200",
  },
  {
    description:
      "Divorce/dissolution of civil partnership (Respondent – uncontested)",
    fee: "	£375",
  },
  {
    description: "Financial clean break consent order (no assets)",
    fee: "£500",
  },
  {
    description: "First Consultation and case assessment (up to 30 Minutes)",
    fee: "Free",
  },
  {
    description:
      "Divorce/dissolution of civil partnership (petitioner – uncontested)",
    fee: "£1200",
  },
  {
    description:
      "Divorce/dissolution of civil partnership (Respondent – uncontested)",
    fee: "	£375",
  },
  {
    description: "Financial clean break consent order (no assets)",
    fee: "£500",
  },
  {
    description: "First Consultation and case assessment (up to 30 Minutes)",
    fee: "Free",
  },
  {
    description:
      "Divorce/dissolution of civil partnership (petitioner – uncontested)",
    fee: "£1200",
  },
  {
    description:
      "Divorce/dissolution of civil partnership (Respondent – uncontested)",
    fee: "	£375",
  },
  {
    description: "Financial clean break consent order (no assets)",
    fee: "£500",
  },
]

export default function Charges() {
  const tableItems = data.map((data, index) => (
    <tr key={index}>
      <td>{data.description}</td>
      <td>{data.fee}</td>
    </tr>
  ))

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>Description</th>
          <th>Our Professional Fee</th>
        </tr>
      </thead>
      <tbody>{tableItems}</tbody>
    </table>
  )
}
